//@ts-nocheck
import React, { useEffect, useState, useReducer } from "react";


import VideoPlayer from "../../../video.tsx";
import MapPlate from "../HazCams/DataPlates/map.tsx"
import QRCode from "react-qr-code";


export default function StormChaserComponent({ chaser, updateMarker, addLiveMarker }) {



  const [videoPlaying, setVideoPlaying] = useState(false);
  const [currentChaser, setCurrentChaser] = useState(false);

  
  const fetchData = async (chaser) => {
    try {
      const response = await fetch(
        `https://realtime.hazcams.wxlogic.com/api/chasers/get_chaser/${chaser}`
      );
      if (!response.ok) {
        setCurrentChaser(null);
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log(jsonData);
      setCurrentChaser(jsonData);
    }
    catch{
      setCurrentChaser(null);
    }
  }

  useEffect(() => {
    addLiveMarker(currentChaser?.geometry?.coordinates[1], currentChaser?.geometry?.coordinates[0]);
  }, [currentChaser]);



  const handleVideoPlayer = (status) => {
    console.log(status);
    if (status == "ok") {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  };

 
  useEffect(() => {
    fetchData(chaser);
  }, [chaser])

  if(!currentChaser){
    return;
  }

  return (
    <div className="basis-1/4 rounded-lg overflow-hidden  items-center -mb-1">
      <div className="flex flex-row justify-between rounded-lg mt-1 mx-1 bg-gray-900 text-white px-4 p-1 text-3xl font-black">
        <div className="flex flex-col">
            <div>{currentChaser?.properties.location}</div>
            <div className="text-sm">{currentChaser?.properties.title}</div>
        </div>        
        <div>{currentChaser?.properties.local_time}</div>
      </div>
      <div className="relative m-1 rounded-lg overflow-hidden">
        {videoPlaying ? null : (
          <div className="absolute top-0  left-0 w-full bg-black aspect-video z-10 rounded-lg overflow-hidden">
            <img
              style={{ filter: "blur(10px)" }}
              src={'/storm_background.JPG'}
            />
            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-white text-lg font-black">
              Link Down
            </div>
          </div>
        )}
        <div className="rounded-lg overflow-hidden">
        <VideoPlayer
          videoUrl={currentChaser?.properties.videoURL}
          playerStatus={handleVideoPlayer}
        />
        </div>
        <div className="grid grid-cols-3 -mx-1 z-50">
        <MapPlate layers={'radar', 'alerts'} location={{lat:currentChaser?.geometry?.coordinates[1], lon:currentChaser?.geometry?.coordinates[0]}}/>
        <div className={`relative h-48  col-span-1 rounded-md p-2 m-1 justify-between  bg-gray-800 overflow-hidden`}>
        <QRCode className="w-full h-full" size={128} value={"https://www.projectmyrsky.com/chaser/" + currentChaser?.properties.id} fgColor={"#fff"} bgColor={"rgba(0,0,0,0)"}/>
        </div>
        </div>
      </div>    
    </div>
  );
}
