import React, { useRef, useMemo, useEffect } from "react";
import VideoJS from "./VideoJS.tsx";
import QRCode from "react-qr-code";

const VideoPlayer = ({ videoUrl, playerStatus, mp4, audio, fullscreen }) => {
  const playerRef = useRef(null);

  

  const videoJsOptions = useMemo(() => ({
    aspectRatio: "16:9",
    playsinline: true,
    muted: !audio,
    autoplay: true,
    controls: false,
    sources: [
      {
        src: videoUrl,
        type: mp4 ? "video/mp4" : "application/x-mpegurl",
      },
    ],
  }), [videoUrl, mp4]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });

    player.on("error", () => {
      playerStatus("error");
    });

    player.on("play", () => {
      playerStatus("ok");
    });

    player.on("playing", () => {
      playerStatus("ok");
    });

    player.on("waiting", () => {
      playerStatus("wait");
    });
  };

  useEffect(() => {
    
  }, [videoUrl])

  return (
    <div className="relative w-full">
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} fullscreen={fullscreen}/>
      {/* Uncomment if QR code is needed
      <div className="absolute bottom-0 right-0 h-28 w-28 backdrop-brightness-150">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          viewBox={`0 0 256 256`}
          value={`https://live.projectmyrsky.com/stream?id=${videoUrl}`}
        />
      </div>
      */}
    </div>
  );
};

export default VideoPlayer;
