//@ts-nocheck
import React, { useEffect, useState } from "react";
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import LoadingPlate from "./loading_plate";

const rangeMultiplier = 5;

interface IconTextProps {
  value: string | null,
  trendline: number[] | null,
  range: { min: number, max: number } | undefined,
}

const interpolateColor = (value: number, colorArray: [number, number[]][]) => {
  for (let i = 1; i < colorArray[0].length; i++) {
    const [stop, color] = colorArray[0][i];
    const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
    const [r2, g2, b2, a2] = color;

    if (value <= stop) {
      const weight = (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
      const r = Math.round(r1 + weight * (r2 - r1));
      const g = Math.round(g1 + weight * (g2 - g1));
      const b = Math.round(b1 + weight * (b2 - b1));
      const a = Math.round(a1 + weight * (a2 - a1));

      return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
    }
  }

  const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
  const [lastR, lastG, lastB, lastA] = lastColor;

  return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
};

const lightenColor = (color: string, percent: number) => {
  const [r, g, b, a] = color
    .substring(color.indexOf("(") + 1, color.indexOf(")"))
    .split(",")
    .map((val) => parseFloat(val.trim()));

  const newR = Math.min(255, r + (percent / 100) * (255 - r));
  const newG = Math.min(255, g + (percent / 100) * (255 - g));
  const newB = Math.min(255, b + (percent / 100) * (255 - b));

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

const createLinearGradient = (color: string, value: number, minValue: number, maxValue: number) => {
  const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
  return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
};

const WetBulbGlobePlate: React.FC<IconTextProps> = ({ value, trendline, range }) => {
  const [minValue, setMinValue] = useState('min');
  const [maxValue, setMaxValue] = useState('max');
  const [linePath, setLinePath] = useState<string | null>(null);

  useEffect(() => {
    if (range && trendline) {
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(String(min));
      setMaxValue(String(max));

      const step = 100 / (trendline.length - 1);
      setLinePath(trendline
        .map((value, index) => {
          const x = (index * step).toFixed(2);
          const y = (
            100 - ((value - (min - rangeMultiplier)) / ((rangeMultiplier + max) - (min - rangeMultiplier))) * 100
          ).toFixed(2);
          return `${x} ${y}`;
        })
        .join(" "));
    } else {
      setMinValue("min");
      setMaxValue("max");
      value = "NEQ";
    }
  }, [trendline, range]);

  if (value === null || trendline === null || range === undefined) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  const colorArray = [[[-94,[110,110,110,255]],
  [32,[110,110,110,255]],
  [50,[73,164,0,256]],
  [65,[160,194,0,256]],
  [72,[200,159,3,256]],
  [77,[188,121,0,256]],
  [80,[192,92,1,256]],
  [84,[201,44,44,255]],
  [88,[128,0,0,255]],
  [89,[0,0,0,255]]]];

  const color = interpolateColor(Number(value), colorArray);
  const linearGradient = createLinearGradient(color, Number(value), Number(minValue), Number(maxValue));

  return (
    <div
  className="col-span-1 h-24 flex items-center justify-between rounded-md p-4 transition-all duration-1000 text-white m-1"
  style={{
    background: `${lightenColor(color, -40)}`,
    position: "relative",
  }}
>
  <svg
    className="absolute inset-0 rounded-md"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    style={{ width: "100%", height: "100%" }}
  >
    <polygon
      points={`0 100, ${linePath}, 100 100`}
      fill={color}
      opacity="1"
    />
  </svg>
  <div className="flex items-center text-white z-10">
    <MultilineChartIcon fontSize="medium" />
  </div>
  <div className="text-3xl text-right z-10 font-black">{Math.round(value)}°F</div>
  <div className="text-lg absolute bottom-0 left-0 p-1 z-10">
    {minValue} | {maxValue}
  </div>
  <div className="text-lg absolute top-0 inset-x-0 p-1 z-10">
    Wet Bulb Globe
  </div>
</div>
  );
};

export default WetBulbGlobePlate;
