//@ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import MainPanel from "./components/MainPanel.tsx";

import "./App.css";

function App() {
  

  return (
    <MainPanel/>
  );
}

export default App;
