//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";

import VideoPlayer from "../../video.tsx";

export default function MultiPanelVideoPlayer({ stream }) {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const handleVideoPlayer = (status) => {
    console.log(status);
    if (status == "ok") {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  };

  return (
    <div className="relative h-full w-full overflow-hidden rounded-lg">
      {videoPlaying ? null : (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-black flex items-center justify-center  z-30">
          <img style={{ filter: "blur(10px)" }} src={"/storm_background.JPG"} />
          <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-white text-lg font-black">
            Video Link Down
          </div>
        </div>
      )}
      <div className="relative top-2 left-2 text-white font-black z-20 backdrop-brightness-75 backdrop-blur-lg w-fit py-2 px-6 rounded-lg">
        <div className="text-lg">{stream.title}</div>
        <div className="text-md">{stream.title_2}</div>
      </div>
      <div className="absolute top-1 right-1 text-white font-black z-20 bg-red-600 w-fit py-2 px-4 rounded-lg text-sm">LIVE</div>
      <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center ">
        <VideoPlayer videoUrl={stream.stream} playerStatus={handleVideoPlayer} />
      </div>
    </div>
  );
}
