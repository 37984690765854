//@ts-nocheck
import React, { useEffect, useState } from "react";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import LoadingPlate from "./loading_plate";

const rangeMultiplier = 5;

interface IconTextProps {
  value: string | null,
  trendline: number[] | null,
  range: { min: number; max: number } | undefined;
}

const interpolateColor = (value: number, colorArray: [number, number[]][]) => {
  for (let i = 1; i < colorArray[0].length; i++) {
    const [stop, color] = colorArray[0][i];
    const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
    const [r2, g2, b2, a2] = color;

    if (value <= stop) {
      const weight =
        (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
      const r = Math.round(r1 + weight * (r2 - r1));
      const g = Math.round(g1 + weight * (g2 - g1));
      const b = Math.round(b1 + weight * (b2 - b1));
      const a = Math.round(a1 + weight * (a2 - a1));

      return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
    }
  }

  const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
  const [lastR, lastG, lastB, lastA] = lastColor;

  return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
};

const lightenColor = (color: string, percent: number) => {
  const [r, g, b, a] = color
    .substring(color.indexOf("(") + 1, color.indexOf(")"))
    .split(",")
    .map((val) => parseFloat(val.trim()));

  const newR = Math.min(255, r + (percent / 100) * (255 - r));
  const newG = Math.min(255, g + (percent / 100) * (255 - g));
  const newB = Math.min(255, b + (percent / 100) * (255 - b));

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

const createLinearGradient = (
  color: string,
  value: number,
  minValue: number,
  maxValue: number,
) => {
  const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
  return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
};

const TemperaturePlate: React.FC<IconTextProps> = ({
  value,
  trendline,
  range
}) => {
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [linePath, setLinePath] = useState<string | null>(null);

  //console.log(value);
  //console.log(trendline);

  useEffect(() => {
    if (range && trendline) {
      //console.log(range);
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(min);
      setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      setLinePath(
        trendline
          .map((value, index) => {
            const x = (index * step).toFixed(2);
            const y = (
              100 -
              ((value - (min - rangeMultiplier)) /
                (rangeMultiplier + max - (min - rangeMultiplier))) *
                100
            ).toFixed(2);
            return `${x} ${y}`;
          })
          .join(" ")
      );
    } else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [trendline, range]);

  if (
    value === null ||
    minValue === null ||
    maxValue === null ||
    linePath === null
  ) {
    return null //Used for non forced panels
  }

  const colorArray = [
    [
      [-94.27, [115, 70, 105, 255]],
      [-67.27, [202, 172, 195, 255]],
      [-40.27, [162, 70, 145, 255]],
      [-13.27, [143, 89, 169, 255]],
      [4.73, [157, 219, 217, 255]],
      [17.33, [106, 191, 181, 255]],
      [24.53, [100, 166, 189, 255]],
      [32, [93, 133, 198, 255]],
      [33.53, [68, 125, 99, 255]],
      [49.73, [128, 147, 24, 255]],
      [69.53, [243, 183, 4, 255]],
      [85.73, [232, 83, 25, 255]],
      [116.33, [71, 14, 0, 255]],
    ],
  ];

  const color = interpolateColor(Number(value), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(value),
    Number(minValue),
    Number(maxValue)
  );

  return (
    <div
  className="col-span-1 h-24 flex items-center justify-between rounded-md p-4 transition-all duration-1000 text-white m-1"
  style={{
    background: `${lightenColor(color, -40)}`,
    position: "relative",
  }}
>
  <svg
    className="absolute inset-0 rounded-md"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    style={{ width: "100%", height: "100%" }}
  >
    <polygon
      points={`0 100, ${linePath}, 100 100`}
      fill={color}
      opacity="1"
    />
  </svg>
  <div className="flex items-center text-white z-10">
    <ThermostatIcon fontSize="medium" />
  </div>
  <div className="text-3xl text-right z-10 font-black">{Math.round(value)}°F</div>
  <div className="text-lg absolute bottom-0 left-0 p-1 z-10">
    {minValue} | {maxValue}
  </div>
  <div className="text-lg absolute top-0 inset-x-0 p-1 z-10">
    Temperature
  </div>
</div>

  );
};

export default TemperaturePlate;
