//@ts-nocheck

export const SPCLayer = async (map, enabled, day) => {
  if (enabled) {
    try {
      const sourceId = "spc";
      const sourceUrl =
        `https://maps-api.wxlogic.com/products/spc/outlook/day${Math.round(day)}.json`;

      // Check if the source already exists
      if (map.getSource(sourceId)) {
        // Update the source data
        map.getSource(sourceId).setData(sourceUrl);
      } else {
        // Add the source if it doesn't exist
        map.addSource(sourceId, {
          type: "geojson",
          data: sourceUrl,
        });
      }

      // Check if the layer already exists
      if (!map.getLayer(sourceId)) {
        map.addLayer(
          {
            id: sourceId,
            type: "fill",
            source: sourceId,
            paint: {
              "fill-color": ["get", "fill"],
              "fill-outline-color": ["get", "stroke"],
              "fill-opacity": 0.5,
            },
          },
          "aeroway-area"
        );
      }
    } catch (error) {
      console.error("Error fetching spc data:", error);
    }
  } else {
    if (map.getLayer("spc")) {
      map.removeLayer("spc");
    }
    
  }
};
