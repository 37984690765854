//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import HazcamComponent from "./PanelComponents/HazCams/HazcamComponent.tsx";
import StormChaserComponent from "./PanelComponents/StormChasers/StormChaserComponent.tsx";

export default function RightSidePanel({ currentView, mapBounds, map, setMapMarkers }) {
  const [enableRightSidePanel, setEnableRightSidePanel] = useState(false);

  const [autoView, setAutoView] = useState();
  const [autoViewActiveView, setAutoViewActiveView] = useState();

  const dummyStation = "florence-al-us-001";
  const dummyChaser = "a38cf438-c02a-4ba7-b33b-d275d3d73278";

  const addLiveMarker = (lat, lon) => {
    console.log(lat);
    
    setMapMarkers(prevMarkers => [
      ...prevMarkers,
      {
        color: "bg-red-300",
        color2: "bg-red-500",
        lat: lat,
        lon: lon
      }
    ]);
    
  }
  

  const fetchData = async (mapBounds, panelSettings) => {
    try {
      var payload = {};

      if (panelSettings.chasers) {
        payload.chasers = {
          enabled: true,
          filter: panelSettings.chasers,
        };
      } else {
        payload.chasers = {
          enabled: false,
        };
      }

      if (panelSettings.hazcams) {
        payload.hazcams = {
          enabled: true,
          filter: panelSettings.hazcams,
        };
      } else {
        payload.hazcams = {
          enabled: false,
        };
      }

      payload.bounds = mapBounds;

      const response = await fetch(
        `https://realtime.hazcams.wxlogic.com/api/get_feeds`,
        {
          method: "POST", // Assuming the API endpoint accepts POST requests for filters
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        setAutoView(null);
        setAutoViewActiveView(null);
        //selfDisable(true);
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      //console.log(jsonData);
      setAutoView(jsonData);
    } catch (error) {
      setAutoView(null);
      setAutoViewActiveView(null);
      setMapMarkers([]);
      //selfDisable(true);
      console.error("Fetching data failed", error);
    }
  };

  useEffect(() => {
    if(!autoView?.features){
      setAutoView(null);
      setAutoViewActiveView(null);
      return;
    }
    var features = autoView.features;
    if (features && features.length > 0) {
      //console.log(features);
      const randomElement =
      features[Math.floor(Math.random() * features.length)];

      
      if (randomElement.properties.type == "hazcam") {
        setAutoViewActiveView(<HazcamComponent hazcam={randomElement.properties.id} addLiveMarker={addLiveMarker}/>);
      } else if (randomElement.properties.type == "chaser") {
        setAutoViewActiveView(
          <StormChaserComponent chaser={randomElement.properties.id} addLiveMarker={addLiveMarker}/>
        );
      } else {
        setMapMarkers([]);
        setAutoViewActiveView(null);
      }
    }
  }, [autoView]);

  useEffect(() => {
    if (currentView?.right_panel) {
      setEnableRightSidePanel(true);
    } else {
      setEnableRightSidePanel(false);
      setMapMarkers([]);
    }
  }, [currentView]);

  useEffect(() => {
    setMapMarkers([]);
    if (currentView.right_panel?.auto_display) {
      fetchData(
        currentView.location_bounds,
        currentView.right_panel.auto_display
      );
    } else {
      setAutoView(null);
      setAutoViewActiveView(null);
      
    }
  }, [currentView]);

  return (
    <div
      className={`right-0 top-0 h-full rounded-lg overflow-hidden transition-all duration-1000 ${
        enableRightSidePanel ? "w-1/4" : "w-0"
      }`}>
      {autoViewActiveView ? autoViewActiveView : null}
      {currentView.right_panel?.hazcam_display && !autoViewActiveView ? (
        <HazcamComponent
          hazcam={currentView.right_panel?.hazcam_display.hazcam}
          filters={currentView.right_panel?.hazcam_display.filters}
          addLiveMarker={addLiveMarker}
        />
      ) : null}
      {currentView?.right_panel?.chaser_display &&
      !currentView.right_panel?.hazcam_display &&
      !autoViewActiveView
        ? currentView?.right_panel?.chaser_display?.chasers
            ?.slice(0, 2)
            .map((chaser, index) => (
              <StormChaserComponent key={index} chaser={chaser} addLiveMarker={addLiveMarker}/>
            ))
        : null}
    </div>
  );
}
