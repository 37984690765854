import React, { useEffect, useState } from "react";

import VideoPlayer from "../video.tsx";

export default function FullScreenVideo({ videoUrls }) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleVideoPlayer = (status) => {
    console.log(status);
    if (status == "ok") {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  };

  useEffect(() => {
    if(videoUrls){
      const randomIndex = Math.floor(Math.random() * videoUrls.length);
      setVideoUrl(videoUrls[randomIndex]);
    }
  }, [videoUrls]);

  return (
    <div className="w-full h-screen relative overflow-hidden">
  {videoPlaying ? null : (
    <div className="absolute inset-0 bg-black aspect-video z-10">
      <img style={{ filter: "blur(10px)" }} src={"/storm_background.JPG"} alt="Background" />
      <div className="absolute inset-0 flex items-center justify-center text-white text-lg font-black">
        WE WILL BE RIGHT BACK
      </div>
    </div>
  )}
  
  <VideoPlayer videoUrl={videoUrl} playerStatus={handleVideoPlayer} mp4={true} audio={true} fullscreen={true}/>
</div>

  );
}
