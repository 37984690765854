//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import Map from "./PanelComponents/Map.tsx";
import MapboxMap from "./PanelComponents/MapboxMap.tsx";

export default function MapPanel({ currentView, currentMarkers, updateBounds, updateCities }) {
  const [mapBasis, setMapBasis] = useState("w-1/2");

  useEffect(() => {
    if (currentView?.right_panel && currentView?.left_panel) {
      setMapBasis("w-1/2");
    } else if (currentView?.right_panel || currentView?.left_panel) {
      setMapBasis("w-3/4");
    } else {
      setMapBasis("w-full");
    }
  });

  return (
    <div
      className={`${mapBasis} rounded-lg overflow-hidden backdrop-brightness-200  transition-all duration-1000`}>
      <MapboxMap currentView={currentView} currentMarkers={currentMarkers} updateBounds={updateBounds} updateCities={updateCities}/>
    </div>
  );
}
