//@ts-nocheck
import mapboxgl from "mapbox-gl";

// Store the current marker objects
let markerObjects = [];

export const MarkerLayer = (map, enabled, markers) => {
  // Function to add markers
  const addMarkers = () => {
    markers.forEach((marker) => {
      let element = document.createElement("div");
      element.innerHTML = `
        <div class="marker-wrapper relative mb-12">
          <div class="flex content-center marker-content  bg-gray-700 p-2 pb-1 rounded-full">
            <div class="relative flex flex-row content-center h-6 w-6">
              <div class="animate-ping absolute inline-flex h-full w-full rounded-full ${marker.color} opacity-75"></div>
              <div class="relative inline-flex rounded-full h-6 w-6 ${marker.color2}"></div>
            </div>
            <div class="ml-2 text-white text-lg font-black uppercase align-middle">Live</div>
          </div>
          <div class="marker-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-gray-700"></div>
        </div>
      `;

      element.style.position = "absolute";
      element.style.left = "0";
      element.style.top = "0";
      element.style.transform = `translate(-50%,-50%)`; // Adjusted transform to center vertically

      const newMarker = new mapboxgl.Marker({ element })
        .setLngLat([marker.lon, marker.lat])
        .addTo(map);

      markerObjects.push(newMarker);
    });
  };

  // Function to remove markers
  const removeMarkers = () => {
    markerObjects.forEach((marker) => marker.remove());
    markerObjects = [];
  };

  // Remove old markers before adding new ones
  if (enabled) {
    try {
      removeMarkers();
      addMarkers();
    } catch (error) {
      console.error("Error adding markers:", error);
    }
  } else {
    removeMarkers();
  }
};
