//@ts-nocheck


export const CameraLayer = async (map, enabled) => {

  if(!map){
    return;
  }

  if(!map.hasImage('stationary-streaming')){
    await map.loadImage('stationary-streaming.png', (error, image) => {
      if(error) throw error;
      map.addImage("stationary-streaming", image);
    })
    
  }





  if (enabled) {
    try {
      fetch("https://realtime.hazcams.wxlogic.com/GetAllActiveFeeds")
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (!map.getSource("video_feeds")) {
            //add new source

            map.addSource("video_feeds", {
              type: "geojson",
              data: data,
            });
          } else {
            //update existing source
            map.getSource("video_feeds").setData(data);
          }

          if (!map.getLayer("video_feeds")) {
            map.addLayer({
              id: "video_feeds",
              type: "symbol",
              source: "video_feeds",
              layout: {
                "icon-image": "stationary-streaming",
                "icon-size": 0.25,
                "icon-allow-overlap": false,
              },
            });
          }
        });
    } catch (error) {
      console.error("Error fetching camera data:", error);
    }
  } else {
    if (map.getLayer("video_feeds")) {
      map.removeLayer("video_feeds");
    }
    
  }
};
