//@ts-nocheck
import React, { useEffect, useState } from "react";

export default function ImagePlate({ sponsor }) {
  useEffect(() => {
    //console.log(sponsor);
  }, [sponsor]);

  if (sponsor) {
    return (
      <div
        style={{ backgroundColor: sponsor.color }}
        className={`col-span-3 h-36 flex justify-center items-center rounded-md p-4 transition-all duration-1000 text-white m-1`}>
        <img
          className="m-auto object-scale-down max-h-full max-w-full"
          src={sponsor.logo}
        />
      </div>
    );
  } else {
    return null;
  }
}
