

export const RadarLayer = async (map, enabled, latestSweepRef) => {
  if (enabled) {
    try {
      const response = await fetch(
        "https://maps-api.wxlogic.com/data/current/1?id=" +
          new Date().toISOString()
      );
      const data = await response.json();
      const latestEntry = data[data.length - 1];
      const latestUrl = `https://maps-api.wxlogic.com/tileserver/${latestEntry.identifier}/{z}/{x}/{y}.png?apiKey=d76038e92ab1e1a586cbb5ef122efa9d`;

      // Set the latest sweep reference to the latest entry
      latestSweepRef.current = latestEntry;

      const latestSourceId = `radar-tiles-${latestEntry.identifier}`;
      const latestLayerId = `radar-layer-${latestEntry.identifier}`;

      if (!map.getSource(latestSourceId)) {
        map.addSource(latestSourceId, {
          type: "raster",
          tiles: [latestUrl],
          tileSize: 256,
        });
      }

      if (!map.getLayer(latestLayerId)) {
        map.addLayer(
          {
            id: latestLayerId,
            type: "raster",
            source: latestSourceId,
            paint: {
              "raster-opacity": 0.75,
            },
            layout: {
              visibility: "visible",
            },
          },
          "railway-transit"
        );
      }

      // Clean up old layers and sources
      for (let i = 0; i < data.length - 1; i++) {
        const entry = data[i];
        const layerId = `radar-layer-${entry.identifier}`;
        const sourceId = `radar-tiles-${entry.identifier}`;

        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
        if (map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      }
    } catch (error) {
      console.error("Error fetching radar data:", error);
    }
  } else {
    if (map && latestSweepRef.current) {
      const layerId = `radar-layer-${latestSweepRef.current.identifier}`;
      const sourceId = `radar-tiles-${latestSweepRef.current.identifier}`;

      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }

      latestSweepRef.current = null;
    }
  }
};
