import React, { useEffect, useState, useRef } from "react";
import {
  WiDaySunny,
  WiCloudy,
  WiRain,
  WiSnow,
  WiThunderstorm,
  WiSprinkle,
  WiFog,
  WiCloud,
  WiThermometer,
  WiHumidity,
  WiStrongWind,
  WiWindDeg,
} from "react-icons/wi";
import "./CityDisplay.css"; // Import the CSS file

export default function CityDisplay({ mapBounds, delay, currentCities }) {
  const [activeCities, setActiveCities] = useState(null);
  const containerRef = useRef(null);
  const sizeRef = useRef(null);

  const weatherIconMapping = {
    Clear: WiDaySunny,
    Clouds: WiCloudy,
    Rain: WiRain,
    Snow: WiSnow,
    Thunderstorm: WiThunderstorm,
    Drizzle: WiSprinkle,
    Atmosphere: WiFog, // This includes various conditions like mist, smoke, haze, etc.
  };

  const getWeatherIcon = (weatherMainType, size) => {
    const IconComponent = weatherIconMapping[weatherMainType] || WiCloudy;
    return <IconComponent size={size} />;
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}




  const colorArray = [
    [-94.27, [115, 70, 105, 255]],
    [-67.27, [202, 172, 195, 255]],
    [-40.27, [162, 70, 145, 255]],
    [-13.27, [143, 89, 169, 255]],
    [4.73, [157, 219, 217, 255]],
    [17.33, [106, 191, 181, 255]],
    [24.53, [100, 166, 189, 255]],
    [32, [93, 133, 198, 255]],
    [33.53, [68, 125, 99, 255]],
    [49.73, [128, 147, 24, 255]],
    [69.53, [243, 183, 4, 255]],
    [85.73, [232, 83, 25, 255]],
    [116.33, [71, 14, 0, 255]],
  ];

  const lightenColor = (color, percent) => {
    const [r, g, b, a] = color
      .substring(color.indexOf("(") + 1, color.indexOf(")"))
      .split(",")
      .map((val) => parseFloat(val.trim()));

    const newR = Math.min(255, r + (percent / 100) * (255 - r));
    const newG = Math.min(255, g + (percent / 100) * (255 - g));
    const newB = Math.min(255, b + (percent / 100) * (255 - b));

    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  };

  const timeAgo = (timestamp) => {

    //console.log(timestamp);
    const now = new Date();
    const timeDifference = now - new Date(timestamp);

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hours >= 1) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes >= 1) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const interpolateColor = (temp) => {
    for (let i = 0; i < colorArray.length - 1; i++) {
      const [temp1, color1] = colorArray[i];
      const [temp2, color2] = colorArray[i + 1];

      if (temp >= temp1 && temp <= temp2) {
        const ratio = (temp - temp1) / (temp2 - temp1);
        const interpolatedColor = color1.map((c1, index) =>
          Math.round(c1 + ratio * (color2[index] - c1))
        );
        return `rgba(${interpolatedColor[0]}, ${interpolatedColor[1]}, ${
          interpolatedColor[2]
        }, ${interpolatedColor[3] / 255})`;
      }
    }
    return `rgba(255, 255, 255, 1)`; // default color if no match
  };

  const dayDescriptor = (timestamp) => {
    const inputDate = new Date(timestamp);
    const currentDate = new Date();

    // Remove time part for comparison
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    if (inputDate.getTime() === currentDate.getTime()) {
      return "Today";
    } else if (inputDate.getTime() === currentDate.getTime() + 86400000) {
      return "Tomorrow";
    } else {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[inputDate.getDay()];
    }
  };

  const generateWeatherPanel = (current, weatherData) => {
    //console.log(weatherData);
    if (current) {
      return (
        <div className="flex flex-row overflow-hidden w-full py-1 row-span-1 h-fit ">
          <div
            style={{
              backgroundColor: lightenColor(
                interpolateColor(weatherData.observations.temperature),
                -90
              ),
            }}
            className="flex flex-row items-center justify-between backdrop-brightness-90 rounded-lg w-full p-1 text-center">
            <div className="flex flex-col justify-center content-center px-6">
              <div className="text-2xl font-black">Current</div>
              <div className="text-lg font-black">
                {getWeatherIcon(weatherData.conditions, 94)}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col px-5">
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center ">
                    <WiThermometer size={48} />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.observations.temperature)}°F
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center">
                    <WiHumidity size={48} />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.observations.humidity)}%
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-5">
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center">
                    <WiStrongWind size={48} />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.observations.wind_speed)} MPH
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center">
                    <WiWindDeg
                      style={{
                        transform: `rotate(${weatherData.observations.wind_direction}deg)`,
                      }}
                      size={48}
                    />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.observations.wind_direction)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row overflow-hidden w-full py-1 row-span-1 h-fit">
          <div
            style={{
              backgroundColor: lightenColor(
                interpolateColor(
                  (weatherData.temp_min + weatherData.temp_max) / 2
                ),
                -80
              ),
            }}
            className="flex flex-row items-center justify-between backdrop-brightness-90 rounded-lg w-full p-1 text-center">
            <div className="flex flex-col px-6">
              <div className="text-2xl font-black">
                {dayDescriptor(Date.parse(weatherData.date) + 8.64e7)}
              </div>
              <div className="text-lg font-black">
                {getWeatherIcon(weatherData.condition, 94)}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col px-5">
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center ">
                    <WiThermometer size={48} />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.temp_min)} /{" "}
                      {Math.round(weatherData.temp_max)}°F
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center">
                    <WiHumidity size={48} />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.humidity)}%
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-5">
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center">
                    <WiStrongWind size={48} />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.wind_speed)} MPH
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-row items-center">
                    <WiWindDeg
                      style={{
                        transform: `rotate(${weatherData.wind_direction}deg)`,
                      }}
                      size={48}
                    />
                    <div className="text-2xl font-black align-middle">
                      {Math.round(weatherData.wind_direction)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const generateCityInfo = () => {
    if (!activeCities) {
      return;
    }
    return activeCities.map((city, index) => (
      <div
        key={index}
        className="flex flex-col bg-gray-700 px-2 pt-6 pb-2 my-1 mr-1 rounded-lg text-white snap-center w-full items-left h-fit">
        <div className="flex text-3xl font-black uppercase w-screen px-4">
          {city.city}, {city.state}
        </div>
        <div className="flex text-lg font-black uppercase py-2 px-4">
          Updated {timeAgo(city.timestamp)}
        </div>
        <div className="grid grid-rows-4">
          {generateWeatherPanel(true, city.current)}
          {generateWeatherPanel(false, city.forecast[0])}
          {generateWeatherPanel(false, city.forecast[1])}
          {generateWeatherPanel(false, city.forecast[2])}
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (!mapBounds) return; // Make sure mapBounds is defined
    if (currentCities.length == 0) return;

    const url = new URL(
      "https://realtime.hazcams.wxlogic.com/api/get_forecast"
    );

    fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(currentCities),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setActiveCities(shuffleArray(data));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [mapBounds]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let currentIndex = 0; // Starting at the first snap point
    const snapPoints = Array.from(
      container.querySelectorAll(".snap-center")
    ).map((element) => element.offsetLeft);

    const scrollInterval = setInterval(() => {
      if (currentIndex >= snapPoints.length - 1) {
        currentIndex = 0;
      } else {
        currentIndex += 1;
      }

      container.scrollTo({
        left: snapPoints[currentIndex],
        behavior: "smooth",
      });
    }, delay);

    return () => clearInterval(scrollInterval);
  }, [activeCities, delay]);

  if (!activeCities) {
    return null;
  }

  return (
    <div className="w-full h-fit" ref={sizeRef}>
      <div
        ref={containerRef}
        className="flex flex-row w-full overflow-x-auto rounded-lg no-scrollbar snap-mandatory snap-x ">
        <div className="px-6 py-2 my-1 mr-1"></div>
        {generateCityInfo()}
        <div className="px-6 py-2 my-1 mr-1"></div>
      </div>
    </div>
  );
}
