//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";


export default function InfoDisplay({ currentView, mapBounds}) {
  

  
  return (
    <div className="flex h-full w-full rounded-lg bg-gray-800 my-1 text-white p-4 items-end">
      {JSON.stringify(mapBounds)}
    </div>
  );
}
