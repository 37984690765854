import React, { useEffect, useState, useRef } from "react";
import "./AlertDisplay.css";

export default function AlertDisplay({ currentView, mapBounds, delay, filters }) {
  const [activeAlerts, setActiveAlerts] = useState([]);
  const scrollContainerRef = useRef(null);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const sizeRef = useRef(null);

  const getLocalTime = (timezone, utcTimestamp) => {
    if (!timezone || !utcTimestamp) {
      return null;
    }
    let date = new Date(utcTimestamp);
    let options = {
      timeZone: timezone,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };
    let localTime = new Intl.DateTimeFormat("en-US", options).format(date);
    return localTime;
  };

  const getFormattedAreas = (areas) => {
    if (!areas) {
      return null;
    }
    let formattedString = "";
    for (const [key, value] of Object.entries(areas)) {
      formattedString += `${key} areas including `;
      formattedString += value.join(", ") + ". ";
    }
    return formattedString.trim();
  };

  const getTailwindColorClass = (alertType) => {
    const alertColorMap = {
      "Tornado Warning": "bg-red-800",
      "Tornado Watch": "bg-yellow-700",
      "Severe Thunderstorm Warning": "bg-orange-800",
      "Severe Thunderstorm Watch": "bg-orange-600",
      "Flood Warning": "bg-blue-800",
      "Flood Watch": "bg-blue-600",
      "Flash Flood Warning": "bg-green-800",
      "Flash Flood Watch": "bg-green-600",
      "Winter Storm Warning": "bg-indigo-800",
      "Winter Storm Watch": "bg-indigo-600",
      "Blizzard Warning": "bg-gray-800",
      "High Wind Warning": "bg-yellow-800",
      "High Wind Watch": "bg-yellow-600",
      "Heat Advisory": "bg-red-600",
      "Excessive Heat Warning": "bg-red-900",
      "Fire Weather Warning (Red Flag Warning)": "bg-red-700",
      "Coastal Flood Warning": "bg-teal-800",
      "Coastal Flood Watch": "bg-teal-600",
      "Hurricane Warning": "bg-green-800",
      "Hurricane Watch": "bg-green-600",
      "Tropical Storm Warning": "bg-green-700",
      "Tropical Storm Watch": "bg-green-500",
      "Dense Fog Advisory": "bg-gray-700",
      "Air Quality Alert": "bg-yellow-700",
    };

    return alertColorMap[alertType] || "bg-gray-500";
  };

  useEffect(() => {
    if (!mapBounds) {
      return;
    }

    fetch(
      `https://realtime.hazcams.wxlogic.com/api/alerts/active?bounds=${JSON.stringify(
        mapBounds
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setActiveAlerts(data); // Assuming the response has an 'alerts' field
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [mapBounds]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    let currentIndex = 0; // Starting at the first snap point
    const snapPoints = Array.from(
      container.querySelectorAll(".snap-center")
    ).map((element) => element.offsetLeft);

    const scrollInterval = setInterval(() => {
      currentIndex = (currentIndex + 1) % snapPoints.length;
      container.scrollTo({
        left: snapPoints[currentIndex],
        behavior: "smooth",
      });
    }, delay);

    return () => clearInterval(scrollInterval);
  }, [activeAlerts, delay]);

  const generateAlertComponents = (alerts) => {
    if (!alerts) {
      return null;
    }

    return alerts.map((alert, index) => {
      if (filters.includes(alert.event) || filters.includes('all')) {
        return (
          <div
            key={index}
            className={`flex flex-col justify-between px-6 py-2 my-1 mr-1 rounded-lg text-white snap-center w-full items-center h-64 ${getTailwindColorClass(alert.event)}`}
          >
            <div className="flex flex-col justify-between w-screen"></div>
            <div className="text-3xl font-black text-center uppercase text-ellipsis">
              {alert.event}
            </div>
            <div className="text-2xl font-black text-center uppercase">
              EXPIRES {getLocalTime(alert.timezone, alert.expires)}
            </div>
            <div className="text-2xl font-black text-center capitalize line-clamp-3">
              {getFormattedAreas(alert.areas)}
            </div>
            <div className="flex flex-row justify-evenly uppercase text-nowrap text-2xl flex-wrap font-black">
              {alert.meta.hail > 0 ? (
                <div className="text-center backdrop-brightness-50 rounded-full py-2 px-6 mt-1 flex items-center  justify-center">
                  {alert.meta.hail}
                  <span className="material-symbols-outlined ml-2">
                    weather_hail
                  </span>
                </div>
              ) : null}
              {alert.meta.wind ? (
                <div className="text-center backdrop-brightness-50 rounded-full py-2 px-6 mt-1 flex items-center justify-center">
                  {alert.meta.wind}
                  <span className="material-symbols-outlined ml-2">air</span>
                </div>
              ) : null}
              {alert.meta.tornado ? (
                <div className="text-center backdrop-brightness-50 rounded-full py-2 px-6 mt-1 flex items-center justify-center">
                  {alert.meta.tornado}
                  <span className="material-symbols-outlined ml-2">tornado</span>
                </div>
              ) : null}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
    
  };

  useEffect(() => {
    const handleResize = () => {
      if (sizeRef.current) {
        setContainerWidth(sizeRef.current.clientWidth);
      }
    };

    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!activeAlerts) {
    return null;
  }

  return (
    <div className="w-full h-fit" ref={sizeRef}>
      <div
        ref={scrollContainerRef}
        className="flex flex-row w-full overflow-x-auto rounded-lg no-scrollbar snap-mandatory snap-x space-x-1">
        {generateAlertComponents(activeAlerts)}
      </div>
    </div>
  );
}
