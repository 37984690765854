//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { RadarLayer } from "./MapComponents/RadarLayer.tsx";
import { AlertLayer } from "./MapComponents/AlertLayer.tsx";
import { SPCLayer } from "./MapComponents/SPCLayer.tsx";
import { CameraLayer } from "./MapComponents/CameraLayer.tsx";
import { MarkerLayer } from "./MapComponents/MarkerLayer.tsx";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZnVuZ3V5cHJvIiwiYSI6ImZkN2EyODEwYTgxNjE1MTk5OGJlODRjM2U2MjIwNDY0In0.4nsadNh6xF8W-w7g7kpjnw";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function MapboxMap({
  currentView,
  currentMarkers,
  updateBounds,
  updateCities,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const onMapMoveEndEvent = useRef(null);
  const [lng] = useState(-98.5795);
  const [lat] = useState(39.8283);
  const [zoom] = useState(4);

  const latestSweepRef = useRef(null);

  useEffect(() => {
    if (!map.current || !currentView.location_bounds) return;

    const fitMapBounds = async () => {
      map.current.fitBounds(currentView.location_bounds, {
        duration: currentView.schedule_transition_time || 5000,
        padding: 20,
        preloadOnly: true,
      });

      await map.current.once("idle");

      map.current.fitBounds(currentView.location_bounds, {
        duration: currentView.schedule_transition_time || 5000,
        essential: true,
      });
    };

    fitMapBounds();
  }, [currentView]);

  useEffect(() => {
    if (map.current) return; // stops map from initializing more than once

    const initializeMap = () => {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `standard_style_v2.json`,
        center: [lng, lat],
        zoom: zoom,
        //antialias: true,
        //projection: 'globe',
        respectPrefersReducedMotion: false,
        attributionControl: true,
      });

      const resizeObserver = new ResizeObserver(() => {
        map.current.resize();
      });

      resizeObserver.observe(mapContainer.current);
    };

    initializeMap();

    const getCities = () => {
      const currentCities = map.current.queryRenderedFeatures({
        layers: ["place_label_city", "place_label_other"],
      });
      var newList = [];
      //console.log("test");
      //console.log(currentCities);

      currentCities.forEach((city) => {
        newList.push({
          name: city.properties.name_en,
          lat: city.geometry.coordinates[1],
          lon: city.geometry.coordinates[0],
        });
      });

      if (newList.length > 0) {
        //console.log(newList);
        updateCities(newList);
      }
    };

    const updateMapBounds = () => {
      updateBounds([
        map.current.getBounds().toArray(),
        map.current.getCenter(),
      ]);
    };

    const onMove = debounce(() => {
      updateMapBounds();
      getCities();
    }, 200); // Adjust the debounce delay as needed

    if (!onMapMoveEndEvent.current) {
      onMapMoveEndEvent.current = map.current.on("moveend", onMove);
    }
  });

  useEffect(() => {
    const runLayers = () => {
      RadarLayer(
        map.current,
        currentView?.enable_layers?.includes("radar"),
        latestSweepRef
      );
      AlertLayer(map.current, currentView?.enable_layers?.includes("alerts"));
      SPCLayer(
        map.current,
        currentView?.enable_layers?.includes("spc_day_1"),
        1
      );
      CameraLayer(map.current, currentView?.enable_layers?.includes("cameras"));
    };

    const checkAndRunLayers = () => {
      if (map.current.isStyleLoaded()) {
        runLayers();
      } else {
        map.current.once("style.load", runLayers);
      }
    };

    if (map.current) {
      checkAndRunLayers();
    }

    return () => {
      if (map.current) {
        map.current.off("style.load", runLayers);
      }
    };
  }, [currentView]);

  useEffect(() => {
    MarkerLayer(map.current, true, currentMarkers);
  }, [currentMarkers]);

  return <div ref={mapContainer} className="w-full h-full" />;
}
