//@ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import LeftSidePanel from "./Panels/LeftSidePanel.tsx";
import MapPanel from "../components/Panels/MapPanel.tsx";
import MultiPanel from "./Panels/MultiPanel.tsx";
import FullScreenVideo from "./Panels/FullScreenVideo.tsx";
import axios from "axios";
import RightSidePanel from "./Panels/RightSidePanel.tsx";

function MainPanel() {
  const [currentView, setCurrentView] = useState({});
  const [currentCities, setCurrentCities] = useState({});
  const [forecast, setForecast] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [mapBounds, setMapBounds] = useState({});
  const [mapCenter, setMapCenter] = useState({});

  const [mapMarkers, setMapMarkers] = useState([]);

  const [colors, setColors] = useState({
    background: "bg-gray-300",
  });

  const updateMapBounds = async (data) => {
    setMapBounds(data[0]);
    setMapCenter(data[1]);
  };

  const updateCities = async (data) => {
    setCurrentCities(data);
  };

  useEffect(() => {
    let eventSource = new EventSource(
      "https://realtime.hazcams.wxlogic.com/api/live/schedule"
    );

    const connect = () => {
      eventSource = new EventSource(
        "https://realtime.hazcams.wxlogic.com/api/live/schedule"
      );
      eventSource.onopen = () => {
        setIsConnected(true);
      };

      eventSource.onerror = () => {
        setIsConnected(false);
        reconnect();
      };

      eventSource.onmessage = (event) => {
        //console.log(event.data);
        setCurrentView(JSON.parse(event.data));
      };
    };

    const reconnect = () => {
      setTimeout(() => {
        console.log("trying to reconnect");
        eventSource.close();
        connect();
      }, 3000); // Retry every 3 seconds
    };

    connect();

    return () => {
      eventSource.close(); // Close the EventSource connection when component unmounts
    };
  }, []);

  const [centerView, setCenterView] = useState("map");

  const disableRightPanel = (state) => {
    if (!state) {
      return;
    }

    // Remove the right_panel from the state if it exists
    const { right_panel, ...updatedState } = currentView;

    // Update the view based on the new state
    setCurrentView(updatedState);

    // Return or use the updated state as needed
    return updatedState;
  };

  //const [centerView, setCenterView] = useState(<MapPanel currentView={currentView} updateBounds={updateMapBounds}/>);
  //<MapPanel currentView={currentView} updateBounds={updateMapBounds}/>
  //<MultiPanel currentView={currentView} updateBounds={updateMapBounds}/>
  return (
    <div className="">
      {currentView.control_type == "standard" ? (
        <div
          className={`flex flex-row p-4 w-screen h-screen space-x-4 ${colors.background}`}>
          <LeftSidePanel
            currentView={currentView}
            mapBounds={mapBounds}
            currentCities={currentCities}
          />
          {centerView == "grid" ? (
            <MultiPanel
              currentView={currentView}
              updateBounds={updateMapBounds}
            />
          ) : null}
          {centerView == "map" ? (
            <MapPanel
              currentView={currentView}
              currentMarkers={mapMarkers}
              updateBounds={updateMapBounds}
              updateCities={updateCities}
            />
          ) : null}
          <RightSidePanel
            currentView={currentView}
            mapBounds={mapBounds}
            selfDisable={disableRightPanel}
            MapMarkers={mapMarkers}
            setMapMarkers={setMapMarkers}
          />
        </div>
      ) : null}

      {currentView.control_type == "video" ? (
        <FullScreenVideo videoUrls={currentView.video_urls} />
      ) : null}
    </div>
  );
}

export default MainPanel;
