//@ts-nocheck
import React, { useEffect, useState } from "react";
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import LoadingPlate from "./loading_plate";

// Type Definitions
interface panelData {
  value: string | null;
  trendline: number[] | null;
  range: { min: number; max: number } | undefined;
}

interface RainDayPlateProps {
  stationId: string;
}

// Constants
const rangeMultiplier = 5;
const colorArray = [[0,[89,89,89,255]],
[1,[90,88,101,255]],
[5,[97,88,132,255]],
[10,[52,117,142,255]],
[30,[11,140,129,255]],
[40,[92,153,100,255]],
[80,[159,157,84,255]],
[120,[170,101,59,256]],
[500,[255,0,90,256]],
[8000,[228,0,255,256]]];

// Component
const RainDayPlate: React.FC<RainDayPlateProps> = ({
  stationId,
}) => {
  // State
  const [panelData, setpanelData] = useState<panelData>({
    value: null,
    trendline: null,
    range: undefined,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  

  // Fetch Lightning Data
  useEffect(() => {
    const fetchData = () => {
      fetch(
        `https://realtime.hazcams.wxlogic.com/api/station/rain/${stationId}`
      )
        .then((response) => response.json())
        .then((data: panelData) => {
          setpanelData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError("Error fetching rain data");
          setLoading(false);
        });
    };
  
    // Fetch data initially
    fetchData();
  
    // Fetch data every minute
    const interval = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute
  
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [stationId]);

  

  // Functions
  // Function to interpolate color based on a value
  const interpolateColor = (value: number) => {
    for (let i = 1; i < colorArray.length; i++) {
      const [stop, color] = colorArray[i];
      const [r1, g1, b1, a1] = colorArray[i - 1][1];
      const [r2, g2, b2, a2] = color;

      if (value <= stop) {
        const weight =
          (value - colorArray[i - 1][0]) / (stop - colorArray[i - 1][0]);
        const r = Math.round(r1 + weight * (r2 - r1));
        const g = Math.round(g1 + weight * (g2 - g1));
        const b = Math.round(b1 + weight * (b2 - b1));
        const a = Math.round(a1 + weight * (a2 - a1));

        return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
      }
    }

    const [lastStop, lastColor] = colorArray[colorArray.length - 1];
    const [lastR, lastG, lastB, lastA] = lastColor;

    return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
  };

  // Function to lighten a color by a percentage
  const lightenColor = (color: string, percent: number) => {
    const [r, g, b, a] = color
      .substring(color.indexOf("(") + 1, color.indexOf(")"))
      .split(",")
      .map((val) => parseFloat(val.trim()));

    const newR = Math.min(255, r + (percent / 100) * (255 - r));
    const newG = Math.min(255, g + (percent / 100) * (255 - g));
    const newB = Math.min(255, b + (percent / 100) * (255 - b));

    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  };

  // Function to create a linear gradient based on color, value, min, and max
  const createLinearGradient = (
    color: string,
    value: number,
    minValue: number,
    maxValue: number
  ) => {
    const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
    return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
  };
  // Render
  if (loading)
    return (
      null
    );
  if (error) return null;
  if (!panelData.value || !panelData.trendline) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  // Data Preparation
  const step = 100 / (panelData.trendline.length - 1);
  const linePath = panelData.trendline
    .map((value, index) => {
      const x = (index * step).toFixed(2);
      const y = (
        100 -
        ((value - (panelData.range.min - rangeMultiplier)) /
          (rangeMultiplier +
            panelData.range.max -
            (panelData.range.min - rangeMultiplier))) *
          100
      ).toFixed(2);
      return `${x} ${y}`;
    })
    .join(" ");

  // Rendering
  const color = interpolateColor(Number(panelData.value));
  const linearGradient = createLinearGradient(
    color,
    Number(panelData.value),
    Number(panelData.range.min),
    Number(panelData.range.max)
  );

  return (
    <div
      className="col-span-1 h-24 flex items-center justify-between rounded-md p-4 transition-all duration-1000 text-white m-1"
      style={{
        background: `${lightenColor(color, -40)}`,
        position: "relative",
      }}>
      <svg
        className="absolute inset-0 rounded-md"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%" }}>
        <polygon
          points={`0 100, ${linePath}, 100 100`}
          fill={color}
          opacity="1"
        />
      </svg>
      <div className="absolute left-2 flex items-center text-white z-10">
        <UmbrellaIcon fontSize="medium" />
      </div>
      <div className="absolute right-2 text-3xl text-right z-10 font-black">
        {panelData.value} in
      </div>

      <div className="text-lg text-left absolute bottom-0 inset-x-0 p-1 z-10">
        24 HR
      </div>
      <div className="text-lg text-left absolute top-0 inset-x-0 p-1 z-10">
        Total Rainfall
      </div>
    </div>
  );
};

export default RainDayPlate;
