//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import queryString from "query-string";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

export default function Map({ currentView, updateBounds }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(-98.5795);
  const [lat] = useState(39.8283);
  const [zoom] = useState(4);

  const latestSweepRef = useRef("");
  const imagesLoaded = useRef(false);

  const onMapMoveEndEvent = useRef(null);

  const flashTimer = useRef(null);

  useEffect(() => {
    if (!map.current) return;
    if (!currentView.location_bounds) return;
    setTimeout(() => {
      map.current.fitBounds(currentView.location_bounds, {
        duration: currentView.schedule_transition_time || 5000,
      });
    }, 1250);
    
  }, [currentView]);

  useEffect(() => {
    if (map.current) return; // stops map from initializing more than once

    const initializeMap = () => {
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: `new_basic_style.json`,
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false,
      });

      map.current.on("error", handleError);
    };

    const handleError = (e) => {
      console.log("map error: ", e);

      // Send the error to the specified API endpoint
      fetch("https://realtime.hazcams.wxlogic.com/api/report_map_error", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ error: e.error }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Error report sent successfully:", data);

          // Restart the map
          restartMap();
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    };

    const restartMap = () => {
      map.current.remove(); // Remove the current map instance
      initializeMap(); // Reinitialize the map
    };

    initializeMap();
  }, []);

  useEffect(() => {
    const removeRadarLayer = () => {
      if (map.current && latestSweepRef.current) {
        const layerId = `radar-layer-${latestSweepRef.current.identifier}`;
        const sourceId = `radar-tiles-${latestSweepRef.current.identifier}`;

        if (map.current.getLayer(layerId)) {
          map.current.removeLayer(layerId);
        }
        if (map.current.getSource(sourceId)) {
          map.current.removeSource(sourceId);
        }

        latestSweepRef.current = null;
      }
    };

    const addRadarLayer = async () => {
      try {
        const response = await fetch(
          "https://maps-api.wxlogic.com/data/current/1?id=" +
            new Date().toISOString()
        );
        const data = await response.json();
        const latestEntry = data[data.length - 1];
        const latestUrl = `https://maps-api.wxlogic.com/tileserver/${latestEntry.identifier}/{z}/{x}/{y}.png?apiKey=d76038e92ab1e1a586cbb5ef122efa9d`;

        // Set the latest sweep reference to the latest entry
        latestSweepRef.current = latestEntry;

        const latestSourceId = `radar-tiles-${latestEntry.identifier}`;
        const latestLayerId = `radar-layer-${latestEntry.identifier}`;

        if (!map.current.getSource(latestSourceId)) {
          map.current.addSource(latestSourceId, {
            type: "raster",
            tiles: [latestUrl],
            tileSize: 256,
          });
        }

        if (!map.current.getLayer(latestLayerId)) {
          map.current.addLayer(
            {
              id: latestLayerId,
              type: "raster",
              source: latestSourceId,
              paint: {
                "raster-opacity": 0.75,
              },
              layout: {
                visibility: "visible",
              },
            },
            "railway-transit"
          );
        }

        // Clean up old layers and sources
        for (let i = 0; i < data.length - 1; i++) {
          const entry = data[i];
          const layerId = `radar-layer-${entry.identifier}`;
          const sourceId = `radar-tiles-${entry.identifier}`;

          if (map.current.getLayer(layerId)) {
            map.current.removeLayer(layerId);
          }
          if (map.current.getSource(sourceId)) {
            map.current.removeSource(sourceId);
          }
        }
      } catch (error) {
        console.error("Error fetching radar data:", error);
        return false;
      }
    };

    const removeSPCFromMap = () => {
      if (map.current.getLayer("spc_day_1")) {
        map.current.removeLayer("spc_day_1");
      }
    };

    const addSpcToMap = () => {
      const sourceId = "spc_day_1";
      const sourceUrl =
        "https://maps-api.wxlogic.com/products/spc/outlook/day1.json";

      // Check if the source already exists
      if (map.current.getSource(sourceId)) {
        // Update the source data
        map.current.getSource(sourceId).setData(sourceUrl);
      } else {
        // Add the source if it doesn't exist
        map.current.addSource(sourceId, {
          type: "geojson",
          data: sourceUrl,
        });
      }

      // Check if the layer already exists
      if (!map.current.getLayer(sourceId)) {
        map.current.addLayer(
          {
            id: sourceId,
            type: "fill",
            source: sourceId,
            paint: {
              "fill-color": ["get", "fill"],
              "fill-outline-color": ["get", "stroke"],
              "fill-opacity": 0.5,
            },
          },
          "building"
        );
      }
    };

    const calcAlertFill = (type) => {
      if (type == "Severe Thunderstorm Warning") {
        return "#dd6b20";
      } else if (type == "Tornado Warning") {
        return "#e53e3e";
      } else if (type == "Flash Flood Warning") {
        return "#38a169";
      } else if (type == "Special Weather Statement") {
        return "#ffe4b5";
      } else if (type == "Winter Storm Watch") {
        return "#03b0d0";
      } else if (type == "Winter Weather Advisory") {
        return "#7B68EE";
      } else if (type == "Winter Storm Warning") {
        return "#FF69B4";
      } else if (type == "Lake Effect Snow Warning") {
        return "#008B8B";
      }
      return "#000";
    };

    const makeZoneFilterAndColorExpression = (zoneAlerts) => {
      var zoneFilter = ["in", "ugc"];
      var colorExpression = ["match", ["get", "ugc"]];

      zoneAlerts.forEach((alert) => {
        for (var i = 0; i < alert.zones.length; i++) {
          let alertColor = calcAlertFill(alert.event);
          if (!zoneFilter.includes(alert.zones[i])) {
            zoneFilter.push(alert.zones[i]);
            colorExpression.push(alert.zones[i]);
            colorExpression.push(alertColor);
          }
        }
      });

      colorExpression.push("#ccc");
      // console.log('zoneFilter', zoneFilter)
      // console.log('colorExpression', colorExpression)

      return { zoneFilter, colorExpression };
    };

    const removeMapAlerts = () => {
      if (map.current.getLayer("polygon-alerts-fill")) {
        map.current.removeLayer("polygon-alerts-fill");
      }

      if (map.current.getLayer("polygon-alerts-poly")) {
        map.current.removeLayer("polygon-alerts-poly");
      }

      if (map.current.getLayer("zone-alerts")) {
        map.current.removeLayer("zone-alerts");
      }
    };

    const updateMapAlerts = () => {
      const loadMapImages = () => {
        const img = new Image();
        img.src = "/stationary-streaming.png";
        img.onload = () => {
          map.current.addImage("stationary-streaming", img);
          imagesLoaded.current = true;
        };
      };

      if (!map.current.getImage("stationary-streaming")) {
        loadMapImages();
      }

      fetch("https://api.wxlogic.com/v1/alerts/active")
        .then((response) => response.json())
        .then((data) => {
          let alerts = data;
          let zoneAlerts = [];
          let featuresCollection = {
            type: "FeatureCollection",
            features: [],
          };

          alerts.forEach((alert) => {
            if (alert.polygon) {
              featuresCollection.features.push({
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [alert.polygon],
                },
                properties: {
                  ...alert,
                  fill: calcAlertFill(alert.event),
                },
              });
            } else {
              zoneAlerts.push(alert);
            }
          });

          if (!map.current.getSource("polygonAlertsSource")) {
            map.current.addSource("polygonAlertsSource", {
              type: "geojson",
              data: featuresCollection,
            });
          } else {
            map.current
              .getSource("polygonAlertsSource")
              .setData(featuresCollection);
          }

          if (!map.current.getLayer("polygon-alerts-fill")) {
            map.current.addLayer(
              {
                id: "polygon-alerts-fill",
                type: "fill",
                source: "polygonAlertsSource",
                paint: {
                  "fill-color": { type: "identity", property: "fill" },
                  "fill-opacity": 0.3,
                },
              },
              "bridge_major"
            );
          }

          if (!map.current.getLayer("polygon-alerts-poly")) {
            map.current.addLayer(
              {
                id: "polygon-alerts-poly",
                type: "line",
                source: "polygonAlertsSource",
                paint: {
                  "line-color": { type: "identity", property: "fill" },
                  "line-opacity": 1,
                  "line-width": 2,
                },
              },
              "bridge_major"
            );
          }

          if (!map.current.getLayer("zone-alerts")) {
            if (map.current.getSource("zone-alerts")) {
              map.current.removeSource("zone-alerts");
            }
            map.current.addLayer(
              {
                id: "zone-alerts",
                type: "fill",
                "source-layer": "mappingzones",
                source: {
                  type: "vector",
                  tiles: [
                    "https://tiles.wxlogic.com/data/zones/{z}/{x}/{y}.pbf",
                  ],
                },
                paint: {
                  "fill-color": "#fff",
                  "fill-opacity": 0.6,
                },
                filter: ["all", ["in", "ugc"], ["==", "type", "z"]],
              },
              "building"
            );
          }

          let { zoneFilter, colorExpression } =
            makeZoneFilterAndColorExpression(zoneAlerts);

          if (zoneFilter.length !== 0) {
            map.current.setFilter("zone-alerts", [
              "all",
              zoneFilter,
              ["in", "type", "c", "z", "mz", "oz"],
            ]);
            map.current.setPaintProperty(
              "zone-alerts",
              "fill-color",
              colorExpression
            );
          }
        });
    };

    const removeCameraFeeds = () => {
      if (map.current.getLayer("video_feeds")) {
        map.current.removeLayer("video_feeds");
      }
    };

    const updateCurrentCameraFeeds = () => {
      fetch("https://realtime.hazcams.wxlogic.com/GetAllActiveFeeds")
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (!map.current.getSource("video_feeds")) {
            //add new source

            map.current.addSource("video_feeds", {
              type: "geojson",
              data: data,
            });
          } else {
            //update existing source
            map.current.getSource("video_feeds").setData(data);
          }

          if (!map.current.getLayer("video_feeds")) {
            map.current.addLayer({
              id: "video_feeds",
              type: "symbol",
              source: "video_feeds",
              layout: {
                "icon-image": "stationary-streaming",
                "icon-size": 0.25,
                "icon-allow-overlap": true,
              },
            });
          }
        });
    };

    const loadMapFeatures = () => {
      if (currentView.enable_layers) {
        if (currentView.enable_layers.includes("radar")) {
          map.current.on("idle", addRadarLayer());
        } else {
          removeRadarLayer();
        }

        if (currentView.enable_layers.includes("spc_day_1")) {
          map.current.on("idle", addSpcToMap());
        } else {
          removeSPCFromMap();
        }

        if (currentView.enable_layers.includes("alerts")) {
          map.current.on("idle", updateMapAlerts());
        } else {
          removeMapAlerts();
        }

        if (currentView.enable_layers.includes("cameras")) {
          map.current.on("idle", updateCurrentCameraFeeds());
        } else {
          removeCameraFeeds();
        }
      }
    };

    loadMapFeatures();
  }, [currentView]);

  useEffect(() => {
    let layerCheckTimer;

    function initLayerFlash() {
      layerCheckTimer = setInterval(() => {
        if (map.current.getLayer("polygon-alerts-poly")) {
          clearInterval(layerCheckTimer);
          flashPolygonAlertsPoly();
        }
      }, 250);
    }

    function flashPolygonAlertsPoly() {
      if (map.current.getLayer("polygon-alerts-poly")) {
        map.current.setPaintProperty(
          "polygon-alerts-poly",
          "line-color",
          "#4f1515"
        );

        flashTimer.current = setTimeout(() => {
          map.current.setPaintProperty("polygon-alerts-poly", "line-color", {
            type: "identity",
            property: "fill",
          });

          flashTimer.current = setTimeout(flashPolygonAlertsPoly, 1500);
        }, 500);
      } else {
        console.warn("Layer 'polygon-alerts-poly' does not exist");
        initLayerFlash(); // Restart the check for the layer
      }
    }

    initLayerFlash();

    return () => {
      if (flashTimer.current) {
        clearTimeout(flashTimer.current);
        flashTimer.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const updateMapBounds = () => {
      updateBounds([
        map.current.getBounds().toArray(),
        map.current.getCenter(),
      ]);
    };

    const onMove = () => {
      updateMapBounds();
      //updateCities();
    };

    if (!onMapMoveEndEvent.current) {
      onMapMoveEndEvent.current = map.current.on("moveend", onMove);
    }
  }, []);



  return <div ref={mapContainer} className="h-full w-full" />;
}
