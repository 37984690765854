//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import MapPanel from "./PanelComponents/Map.tsx";
import MultiPanelVideoPlayer from "./MultiPanelHelpers/MultiPanelVideoPlayer.tsx";

export default function MultiPanel({ currentView, updateBounds }) {
  const TestStream = {
    title: "TITLE",
    title_2: "SECONDARY TITLE",
    stream:
      "https://node1.cameras.wxlogic.com/memfs/b638ad19-5bee-4fef-b65b-af660d57a8be.m3u8",
  };

  return (
    <div className="basis-1/2 rounded-lg overflow-hidden backdrop-brightness-150 p-2">
      <div className="grid grid-rows-2 w-full h-full gap-2">
        <div className="grid grid-cols-2 w-full h-full gap-2">
          <div className="w-full h-full rounded-lg bg-gray-800 rounded-lg overflow-hidden">
            <MultiPanelVideoPlayer stream={TestStream} />
          </div>
          <div className="w-full h-full rounded-lg bg-gray-800">
          <div className="w-full h-full rounded-lg bg-gray-800 rounded-lg overflow-hidden">
            <MultiPanelVideoPlayer stream={""} />
          </div>
          </div>
        </div>
        <div className="grid grid-cols-2 w-full h-full gap-2">
          <div className="w-full h-full rounded-lg bg-gray-800">
          <div className="w-full h-full rounded-lg bg-gray-800 rounded-lg overflow-hidden">
            <MultiPanelVideoPlayer stream={TestStream} />
          </div>
          </div>
          <div className="w-full h-full rounded-lg bg-gray-800 rounded-lg overflow-hidden">
            <MapPanel currentView={currentView} updateBounds={updateBounds}/>
          </div>
        </div>
      </div>
    </div>
  );
}
