//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import CityDisplay from "./PanelComponents/DataDisplays/CityDisplay.tsx";
import AlertDisplay from "./PanelComponents/DataDisplays/AlertDisplay.tsx";
import InfoDisplay from "./PanelComponents/DataDisplays/InfoDisplay.tsx";

export default function LeftSidePanel({
  currentView,
  mapBounds,
  currentCities,
}) {
  const [DisplayTitleColor, setDisplayTitleColor] = useState("bg-gray-600");
  const [DisplayHeadlineColor, setDisplayHeadlineColor] =
    useState("bg-gray-600");
  const [activeCities, setActiveCities] = useState(null);

  const [leftSidePanelLayout, setLeftSidePanelLayout] = useState();
  const [enableLeftSidePanel, setEnableLeftSidePanel] = useState(false);

  const colors = (color) => {
    switch (color) {
      case "yellow":
        return "bg-yellow-600";
      case "red":
        return "bg-red-600";
      default:
        return "bg-gray-600";
    }
  };

  useEffect(() => {
    setDisplayTitleColor(colors(currentView.display_title_color));
    setDisplayHeadlineColor(colors(currentView.display_headline_color));
  }, [currentView]);

  useEffect(() => {
    if (currentView?.left_panel) {
      setEnableLeftSidePanel(true);
    } else {
      setEnableLeftSidePanel(false);
    }
  }, [currentView]);

  if (!enableLeftSidePanel) {
    return null;
  }

  return (
    <div className="flex flex-col basis-1/4 rounded-lg overflow-hidden  px-1 ">
      

      <div
        className={`text-white text-4xl rounded-lg text-center font-black uppercase content-center text-ellipsis align-middle ${DisplayTitleColor} px-4 w-full my-1 py-6 `}>
        {currentView?.display_title || "Live Weather Now"}
      </div>

      <div className="flex flex-row">
        <div
          className={`text-white text-3xl rounded-lg text-center font-black uppercase content-center bg-gray-800 px-4 w-full my-1 mr-2 py-6`}>
          {currentView?.display_region || "United States"}
        </div>
        <div
          className={`text-white text-3xl rounded-lg text-center font-black uppercase content-center  ${DisplayHeadlineColor} px-4 w-full my-1 py-6`}>
          {currentView?.display_headline || "GENERAL"}
        </div>
      </div>

      {currentView?.left_panel?.alert_display ? (
        <AlertDisplay
          currentView={currentView}
          mapBounds={currentView.location_bounds}
          delay={currentView.left_panel.alert_display.duration || 5000}
          filters={currentView.left_panel.alert_display.filters || ["all"]}
        />
      ) : null}

      {currentView?.left_panel?.city_display ? (
        <CityDisplay
          mapBounds={mapBounds}
          delay={currentView.left_panel.city_display.duration || 5000}
          currentCities={currentCities}
        />
      ) : null}

      <InfoDisplay mapBounds={mapBounds} />
      <div
        style={{
          backgroundColor:
            currentView?.display_logo_background_color || "#4B5563",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={`text-white text-4xl rounded-lg text-center font-black uppercase content-center text-ellipsis align-middle px-4 w-full my-1 py-6 h-36 transition-all duration-1000`}>
        <img
          src={(currentView?.display_logo || "/icons/logo.png")}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
}
