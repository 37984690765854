//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

export default function MapPanel({ layers, location }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
 

  const [activeLayers, setActiveLayers] = useState({});
  const [radarTimestamp, setRadarTimestamp] = useState("RADAR INVALID");

  const latestSweepRef = useRef("");
  

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      center: [location.lon, location.lat],
      zoom: 8,
      style: `../dark-map.json`,
      attributionControl: false,
      interactive: false
    });
  }, []);

  useEffect(() => {
    if (!map.current) return;
    if (!location) return;
    map.current.jumpTo({
        center: [location.lon, location.lat]
    });
}, [location]);


  useEffect(() => {
    //main layers effeect
    const radarUpdateInterval = setInterval(() => {
      fetch("https://maps-api.wxlogic.com/data/current/1?id=" + new Date())
        .then((response) => response.json())
        .then((data) => {
          const latestEntry = data[data.length - 1];
          const latestUrl = `https://maps-api.wxlogic.com/tileserver/${latestEntry.identifier}/{z}/{x}/{y}.png?apiKey=d76038e92ab1e1a586cbb5ef122efa9d`;
          if (latestSweepRef.current != latestUrl) {
            console.log("updating radar");
            console.log(latestSweepRef.current);
            console.log(latestUrl);
            setRadarTimestamp(latestEntry.timestamp);
            addRadarLayer();
          } else {
            console.log("no radar update");
          }
        });
    }, 10000); // 60000 milliseconds = 1 minute

    const removeRadarLayer = () => {
      if (map.current) {
        if (map.current.getLayer("radar-layer" + latestSweepRef.current)) {
          map.current.removeLayer("radar-layer" + latestSweepRef.current);
          setActiveLayers({ ...activeLayers, radar: false });
        }
      }
    };

    const addRadarLayer = () => {
      fetch("https://maps-api.wxlogic.com/data/current/1")
        .then((response) => response.json())
        .then((data) => {
          const latestEntry = data[data.length - 1];
          const latestUrl = `https://maps-api.wxlogic.com/tileserver/${latestEntry.identifier}/{z}/{x}/{y}.png?apiKey=d76038e92ab1e1a586cbb5ef122efa9d`;
          const lastSweep = latestSweepRef.current;

          setRadarTimestamp(latestEntry.timestamp);

          latestSweepRef.current = latestUrl;

          if (!map.current.getSource(String("radar-tiles" + latestUrl))) {
            map.current.addSource(String("radar-tiles" + latestUrl), {
              type: "raster",
              tiles: [latestUrl],
              tileSize: 256,
            });
          }

          if (!map.current.getLayer(String("radar-layer" + latestUrl))) {
            map.current.addLayer(
              {
                id: String("radar-layer" + latestUrl),
                type: "raster",
                source: "radar-tiles" + latestUrl,
                paint: {
                  "raster-opacity": 0.75, // Adjust opacity as needed
                },
                layout: {
                  visibility: "visible",
                },
              },
              "place_other"
            );
          }

          setActiveLayers({ ...activeLayers, radar: true });
          if (map.current.getLayer(String("radar-layer" + lastSweep))) {
            map.current.removeLayer(String("radar-layer" + lastSweep));
          }
          if (map.current.getSource(String("radar-tiles" + lastSweep))) {
            map.current.removeSource(String("radar-tiles" + lastSweep));
          }
        })
        .catch((error) => {
          console.error("Error fetching radar data:", error);
          return false;
        });
    };

    const addZonesToMap = () => {
      map.current.addLayer(
        {
          id: "zone-alerts",
          type: "fill",
          "source-layer": "mappingzones",
          source: {
            type: "vector",
            tiles: ["https://tiles.wxlogic.com/data/zones/{z}/{x}/{y}.pbf"],
          },
          paint: {
            "fill-color": "#fff",
            "fill-opacity": 0.6,
          },
          filter: ["all", ["in", "ugc"], ["==", "type", "z"]],
        },
        "building"
      );
    };

    const calcAlertFill = (type) => {
      if (type == "Severe Thunderstorm Warning") {
        return "#dd6b20";
      } else if (type == "Tornado Warning") {
        return "#e53e3e";
      } else if (type == "Flash Flood Warning") {
        return "#38a169";
      } else if (type == "Special Weather Statement") {
        return "#ffe4b5";
      } else if (type == "Winter Storm Watch") {
        return "#03b0d0";
      } else if (type == "Winter Weather Advisory") {
        return "#7B68EE";
      } else if (type == "Winter Storm Warning") {
        return "#FF69B4";
      } else if (type == "Lake Effect Snow Warning") {
        return "#008B8B";
      }
      return "#000";
    };

    const makeZoneFilterAndColorExpression = (zoneAlerts) => {
      var zoneFilter = ["in", "ugc"];
      var colorExpression = ["match", ["get", "ugc"]];

      zoneAlerts.forEach((alert) => {
        for (var i = 0; i < alert.zones.length; i++) {
          let alertColor = calcAlertFill(alert.event);
          if (!zoneFilter.includes(alert.zones[i])) {
            zoneFilter.push(alert.zones[i]);
            colorExpression.push(alert.zones[i]);
            colorExpression.push(alertColor);
          }
        }
      });

      colorExpression.push("#ccc");
      // console.log('zoneFilter', zoneFilter)
      // console.log('colorExpression', colorExpression)

      return { zoneFilter, colorExpression };
    };

    const updateMapAlerts = () => {
      fetch("https://api.wxlogic.com/v1/alerts/active")
        .then((response) => response.json())
        .then((data) => {
          let alerts = data;
          let zoneAlerts = [];
          let featuresCollection = {
            type: "FeatureCollection",
            features: [],
          };

          alerts.forEach((alert) => {
            if (alert.polygon) {
              featuresCollection.features.push({
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [alert.polygon],
                },
                properties: {
                  ...alert,
                  fill: calcAlertFill(alert.event),
                },
              });
            } else {
              zoneAlerts.push(alert);
            }
          });

          if (!map.current.getSource("polygonAlertsSource")) {
            map.current.addSource("polygonAlertsSource", {
              type: "geojson",
              data: featuresCollection,
            });
          } else {
            map.current
              .getSource("polygonAlertsSource")
              .setData(featuresCollection);
          }

          if (!map.current.getLayer("polygon-alerts-fill")) {
            map.current.addLayer(
              {
                id: "polygon-alerts-fill",
                type: "fill",
                source: "polygonAlertsSource",
                paint: {
                  "fill-color": { type: "identity", property: "fill" },
                  "fill-opacity": 0.3,
                },
              },
              "highway_name_motorway"
            );
          }

          if (!map.current.getLayer("polygon-alerts-poly")) {
            map.current.addLayer(
              {
                id: "polygon-alerts-poly",
                type: "line",
                source: "polygonAlertsSource",
                paint: {
                  "line-color": { type: "identity", property: "fill" },
                  "line-opacity": 1,
                  "line-width": 2,
                },
              },
              "highway_name_motorway"
            );
          }

          let { zoneFilter, colorExpression } =
            makeZoneFilterAndColorExpression(zoneAlerts);

          if (zoneFilter.length !== 0) {
            map.current.setFilter("zone-alerts", [
              "all",
              zoneFilter,
              ["in", "type", "c", "z", "mz", "oz"],
            ]);
            map.current.setPaintProperty(
              "zone-alerts",
              "fill-color",
              colorExpression
            );
          }
        });
    };

    const loadMapFeatures = () => {
      if (layers.includes("radar")) {
        map.current.on("idle", addRadarLayer());
      } else {
        removeRadarLayer();
      }

      if (layers.includes("spc_day_1")) {
        map.current.on("idle", addSpcToMap());
      } else {
        removeSPCFromMap();
      }

      if (layers.includes("alerts")) {
        map.current.on("idle", updateMapAlerts());
      }
    };

    if (map.current) {
      map.current.on("load", () => {
        loadMapFeatures();
        addZonesToMap();
      });
    }

    return () => clearInterval(radarUpdateInterval);
  }, [layers]);

  return (
    <div
      ref={mapContainer}
      className={`relative h-48  col-span-2 rounded-md  m-1 justify-between bg-gray-100 dark:bg-gray-800 overflow-hidden`}>
      <div className=" flex absolute w-full h-full justify-center items-center">
        <span className="relative flex h-3 w-3 z-40">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-100 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-200"></span>
        </span>
      </div>
      <div className="absolute right-1 bottom-1 text-white text-xs z-40">
        {radarTimestamp} UTC
      </div>
    </div>
  );
}
