//@ts-nocheck
import React, { useEffect, useState, useReducer } from "react";

import TemperaturePlate from "../HazCams/DataPlates/temperature_plate.tsx";
import HumidityPlate from "../HazCams/DataPlates/humidity_plate.tsx";
import WindPlate from "../HazCams/DataPlates/wind_plate.tsx";
import WindDirectionPlate from "../HazCams/DataPlates/wind_direction_plate.tsx";
import UVPlate from "../HazCams/DataPlates/uv_plate.tsx";
import SolarRadiationPlate from "../HazCams/DataPlates/solar_radiation_plate.tsx";
import RainRate from "../HazCams/DataPlates/rainrate_plate.tsx";
import RainDay from "../HazCams/DataPlates/rainday_plate.tsx";
import DewPointPlate from "../HazCams/DataPlates/dew_plate.tsx";
import PressurePlate from "../HazCams/DataPlates/pressure_plate.tsx";
import WetBulbGlobePlate from "../HazCams/DataPlates/wet_bulb_globe_temp.tsx";
import WindChillPlate from "../HazCams/DataPlates/wind_chill_plate";
import ImagePlate from "../HazCams/DataPlates/image_plate.tsx";
import VideoPlayer from "../../../video.tsx";
import MapPlate from "../HazCams/DataPlates/map.tsx";
import QRCode from "react-qr-code";

const UPDATE_VALUE = "UPDATE_VALUE";
const REPLACE_DATA = "REPLACE_DATA";
const UPDATE_LIGHTNING_DATA = "UPDATE_LIGHTNING_DATA";

function newDataReducer(state, action) {
  if (state === null && action == UPDATE_VALUE) {
    return state;
  }

  switch (action.type) {
    case REPLACE_DATA:
      return action.payload;

    case UPDATE_VALUE:
      const { parameter, value } = action.payload;
      const parameterData =
        state && state[parameter]
          ? state[parameter]
          : { value: 0, range: { min: 0, max: 0 } };
      const newValue = parseFloat(value);
      const newMin =
        newValue < parameterData.range.min ? newValue : parameterData.range.min;
      const newMax =
        newValue > parameterData.range.max ? newValue : parameterData.range.max;

      return {
        ...state,
        [parameter]: {
          ...parameterData,
          value: newValue,
          range: {
            min: newMin,
            max: newMax,
          },
        },
      };

    default:
      return state;
  }
}

export default function HazcamComponent({ hazcam, filters, addLiveMarker}) {
  const [obsData, setObsData] = useReducer(newDataReducer, null);
  const [stationData, setStationData] = useState(null);
  const [stationDataFetched, setStationDataFetched] = useState(false);
  const [historicalDataFetched, setHistoricalDataFetched] = useState(false);
  const [eventListenerConnected, setEventListenerConnected] = useState(false);

  const [videoPlaying, setVideoPlaying] = useState(false);

  const [enabledPanels, setEnabledPanels] = useState([
    "temperature",
    "humidity",
    "dewpoint",
    "windspeed",
    "winddirection",
    "wetbulbglobe",
    "uv",
    "solar",
    "pressure",
    "rainrate",
    "raintotal",
    "sponsor",
  ]);

  const fetchData = async (interval) => {
    try {
      const response = await fetch(
        `https://realtime.hazcams.wxlogic.com/api/station/${hazcam}`
      );
      if (!response.ok) {
        setStationDataFetched(false);
        throw new Error("Network response was not ok");
      }
      setStationDataFetched(true);
      const jsonData = await response.json();
      delete jsonData.history;
      delete jsonData.range;
      setStationData(jsonData);
      addLiveMarker(jsonData?.geo?.lat, jsonData?.geo?.lon)
      const historical_response = await fetch(
        `https://realtime.hazcams.wxlogic.com/api/station/historical/${hazcam}/${interval}`
      );
      if (!historical_response.ok) {
        setHistoricalDataFetched(false);
        throw new Error("Network response was not ok");
      }
      setHistoricalDataFetched(true);
      const historicalJsonData = await historical_response.json();
      setObsData({ type: REPLACE_DATA, payload: historicalJsonData });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = (e) => {
    const newData = JSON.parse(e.data);
    for (const obs in newData) {
      if (obs === "lightning_distance") {
        // do nothing
      } else {
        setObsData({
          type: UPDATE_VALUE,
          payload: { parameter: obs, value: newData[obs] },
        });
      }
    }
  };

  const handleVideoPlayer = (status) => {
    //console.log(status);
    if (status == "ok") {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  };

  const openEventListener = () => {
    const source = new EventSource(
      `https://realtime.hazcams.wxlogic.com/api/subscribe`
    );

    source.addEventListener("open", () => {
      setEventListenerConnected(true);
    });

    source.addEventListener(`${hazcam}`, handleUpdate);

    source.addEventListener("error", (e) => {
      setEventListenerConnected(false);
      console.error("Error connecting to Realtime Server", e);
    });

    return source;
  };

  useEffect(() => {
    const source = openEventListener();

    return () => {
      source.close();
      setEventListenerConnected(false);
      source.removeEventListener(`${hazcam}`, handleUpdate);
    };
  }, [hazcam]);

  useEffect(() => {
    fetchData(43200);

    
  }, [hazcam]);

  useEffect(() => {
    if(filters){
    setEnabledPanels(filters);
    }
  }, filters);

  const getLocalTime = (timezone) => {
    // Create a new Date object
    let date = new Date();

    // Get the options for the Intl.DateTimeFormat
    let options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: timezone,
      timeZoneName: "short",
    };

    // Format the date using the options and the specified timezone
    let formatter = new Intl.DateTimeFormat("en-US", options);
    let formattedTime = formatter.format(date);

    return formattedTime;
  };

  if (!stationData) {
    return;
  }

  return (
    <div className="basis-1/4 rounded-lg overflow-hidden items-center">
      <div className="flex flex-row justify-between rounded-lg m-1 bg-gray-900 text-white px-4 py-4 text-3xl font-black">
        <div>{stationData?.name}</div>
        <div>{getLocalTime(stationData?.timezone)}</div>
      </div>
      <div className="relative m-1 rounded-lg overflow-hidden">
        {videoPlaying ? null : (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black  z-30">
            <img
              style={{ filter: "blur(10px)" }}
              src={stationData?.video_servers[0]?.snapshot_url}
            />
            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-white text-lg font-black">
              Video Link Down
            </div>
          </div>
        )}
        <VideoPlayer
          className="rounded-md overflow-hidden"
          videoUrl={stationData?.video_servers[0]?.camera_url}
          playerStatus={handleVideoPlayer}
        />
      </div>
      <div className="grid grid-cols-3">
        {enabledPanels.includes("temperature") && (
          <TemperaturePlate
            value={obsData?.temperature?.value}
            trendline={obsData?.temperature?.trendline}
            range={obsData?.temperature?.range}
          />
        )}
        {enabledPanels.includes("humidity") && (
          <HumidityPlate
            value={obsData?.humidity?.value}
            trendline={obsData?.humidity?.trendline}
            range={obsData?.humidity?.range}
          />
        )}
        {enabledPanels.includes("dewpoint") && (
          <DewPointPlate
            value={obsData?.dew_point?.value}
            trendline={obsData?.dew_point?.trendline}
            range={obsData?.dew_point?.range}
          />
        )}
        {enabledPanels.includes("windspeed") && (
          <WindPlate
            value={obsData?.wind_now?.value}
            trendline={obsData?.wind_now?.trendline}
            range={obsData?.wind_now?.range}
            direction={obsData?.wind_direction?.range}
          />
        )}
        {enabledPanels.includes("winddirection") && (
          <WindDirectionPlate
            value={obsData?.wind_now?.value}
            trendline={obsData?.wind_direction?.trendline}
            range={obsData?.wind_direction?.range}
            direction={obsData?.wind_direction?.value}
          />
        )}
        {enabledPanels.includes("wetbulbglobe") && (
          <WetBulbGlobePlate
            value={obsData?.wet_bulb_globe?.value}
            trendline={obsData?.wet_bulb_globe?.trendline}
            range={obsData?.wet_bulb_globe?.range}
          />
        )}
        {enabledPanels.includes("uv") && (
          <UVPlate
            value={obsData?.uv?.value}
            trendline={obsData?.uv?.trendline}
            range={obsData?.uv?.range}
          />
        )}
        {enabledPanels.includes("solar") && (
          <SolarRadiationPlate
            value={obsData?.solar_radiation?.value}
            trendline={obsData?.solar_radiation?.trendline}
            range={obsData?.solar_radiation?.range}
          />
        )}
        {enabledPanels.includes("pressure") && (
          <PressurePlate
            value={obsData?.pressure?.value}
            trendline={obsData?.pressure?.trendline}
            range={obsData?.pressure?.range}
          />
        )}
        {enabledPanels.includes("rainrate") && (
          <RainRate
            value={obsData?.rain_min?.value}
            trendline={obsData?.rain_min?.trendline}
            range={obsData?.rain_min?.range}
          />
        )}
        {enabledPanels.includes("raintotal") && <RainDay stationId={hazcam} />}
        <div className="grid grid-cols-3 col-span-3">
          <div
            className={`relative h-48  col-span-1 rounded-md p-2 m-1 justify-between  bg-gray-800 overflow-hidden`}>
            <QRCode
              className="w-full h-full"
              size={128}
              value={"https://www.projectmyrsky.com/station/" + hazcam}
              fgColor={"#fff"}
              bgColor={"rgba(0,0,0,0)"}
            />
          </div>
          <MapPlate layers={["radar", "alerts"]} location={stationData?.geo} />
        </div>
        {enabledPanels.includes("sponsor") && (
          <ImagePlate sponsor={stationData?.sponsor} />
        )}
        <div className="col-span-3 grid grid-cols-3"></div>
      </div>
    </div>
  );
}
