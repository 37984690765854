//@ts-nocheck
import React, { useEffect, useState } from "react";
import WaterIcon from "@mui/icons-material/Water";
import LoadingPlate from "./loading_plate";

const rangeMultiplier = 5;

interface IconTextProps {
  value: string | null;
  trendline: number[] | null;
  range: { min: number; max: number } | undefined;
}

const interpolateColor = (value: number, colorArray: [number, number[]][]) => {
  for (let i = 1; i < colorArray[0].length; i++) {
    const [stop, color] = colorArray[0][i];
    const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
    const [r2, g2, b2, a2] = color;

    if (value <= stop) {
      const weight =
        (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
      const r = Math.round(r1 + weight * (r2 - r1));
      const g = Math.round(g1 + weight * (g2 - g1));
      const b = Math.round(b1 + weight * (b2 - b1));
      const a = Math.round(a1 + weight * (a2 - a1));

      return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
    }
  }

  const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
  const [lastR, lastG, lastB, lastA] = lastColor;

  return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
};

const lightenColor = (color: string, percent: number) => {
  const [r, g, b, a] = color
    .substring(color.indexOf("(") + 1, color.indexOf(")"))
    .split(",")
    .map((val) => parseFloat(val.trim()));

  const newR = Math.min(255, r + (percent / 100) * (255 - r));
  const newG = Math.min(255, g + (percent / 100) * (255 - g));
  const newB = Math.min(255, b + (percent / 100) * (255 - b));

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

const createLinearGradient = (
  color: string,
  value: number,
  minValue: number,
  maxValue: number
) => {
  const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
  return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
};

const HumidityPlate: React.FC<IconTextProps> = ({
  value,
  trendline,
  range
}) => {
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [linePath, setLinePath] = useState<string | null>(null);

  useEffect(() => {
    if (range && trendline) {
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(min);
      setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      setLinePath(
        trendline
          .map((value, index) => {
            const x = (index * step).toFixed(2);
            const y = (
              100 -
              ((value - (min - rangeMultiplier)) /
                (rangeMultiplier + max - (min - rangeMultiplier))) *
                100
            ).toFixed(2);
            return `${x} ${y}`;
          })
          .join(" ")
      );
    } else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [trendline, range]);

  if (
    value === null ||
    minValue === null ||
    maxValue === null ||
    linePath === null
  ) {
    return null //Used for non forced panels
  }

  const colorArray = [
    [
      [0, [173, 85, 56, 255]],
      [30, [173, 110, 56, 255]],
      [40, [173, 146, 56, 255]],
      [50, [105, 173, 56, 255]],
      [60, [56, 173, 121, 255]],
      [70, [56, 174, 173, 255]],
      [75, [56, 160, 173, 255]],
      [80, [56, 157, 173, 255]],
      [83, [56, 148, 173, 255]],
      [87, [56, 135, 173, 255]],
      [90, [56, 132, 173, 255]],
      [93, [56, 123, 173, 255]],
      [97, [56, 98, 157, 255]],
      [100, [56, 70, 114, 255]],
    ],
  ];

  const color = interpolateColor(Number(value), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(value),
    Number(minValue),
    Number(maxValue)
  );

  return (
    <div
  className="col-span-1 h-24 flex items-center justify-between rounded-md p-4 transition-all duration-1000 text-white m-1"
  style={{
    background: `${lightenColor(color, -40)}`,
    position: "relative",
  }}
>
  <svg
    className="absolute inset-0 rounded-md"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    style={{ width: "100%", height: "100%" }}
  >
    <polygon
      points={`0 100, ${linePath}, 100 100`}
      fill={color}
      opacity="1"
    />
  </svg>
  <div className="flex items-center text-white z-10">
    <WaterIcon fontSize="medium" />
  </div>
  <div className="text-3xl text-right z-10 font-black">{Math.round(value)}%</div>
  <div className="text-lg absolute bottom-0 left-0 p-1 z-10">
    {minValue} | {maxValue}
  </div>
  <div className="text-lg absolute top-0 inset-x-0 p-1 z-10">
    Humidity
  </div>
</div>
  );
};

export default HumidityPlate;
