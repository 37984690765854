//@ts-nocheck
import React, { useEffect, useState } from "react";
import AirIcon from "@mui/icons-material/Air";

const rangeMultiplier = 5; //set this for a pretty graph
//const minValue = 0;
//const maxValue = 35;

interface Props {
  value: number | null;
  direction: number | null;
  trendline: number[] | null;
  range: { min: number; max: number } | undefined;
}

const interpolateColor = (value: number, colorArray) => {
  for (let i = 1; i < colorArray[0].length; i++) {
    const [stop, color] = colorArray[0][i];
    const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
    const [r2, g2, b2, a2] = color;

    if (value <= stop) {
      const weight =
        (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
      const r = Math.round(r1 + weight * (r2 - r1));
      const g = Math.round(g1 + weight * (g2 - g1));
      const b = Math.round(b1 + weight * (b2 - b1));
      const a = Math.round(a1 + weight * (a2 - a1));

      return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
    }
  }

  // If the value is beyond the last stop, use the color of the last stop
  const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
  const [lastR, lastG, lastB, lastA] = lastColor;

  return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
};

const lightenColor = (color, percent) => {
  const [r, g, b, a] = color
    .substring(color.indexOf("(") + 1, color.indexOf(")"))
    .split(",")
    .map((val) => parseFloat(val.trim()));

  const newR = Math.min(255, r + (percent / 100) * (255 - r));
  const newG = Math.min(255, g + (percent / 100) * (255 - g));
  const newB = Math.min(255, b + (percent / 100) * (255 - b));

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

const convertToCardinal = (degrees) => {
  const degreeValue = parseFloat(degrees);

  if (!isNaN(degreeValue)) {
    const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
    const index = Math.round(degreeValue / 45) % 8;
    return directions[index];
  } else {
    return "";
  }
};

//const createLinearGradient = (color, value, minValue, maxValue) => {
//  const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
//  return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
//};

const WindDirectionPlate: React.FC<Props> = ({
  value,
  direction,
  trendline,
  range,
}) => {
  const colorArray = [
    [
      [0, [98, 113, 183, 255]],
      [1, [57, 97, 159, 255]],
      [3, [74, 148, 169, 255]],
      [5, [77, 141, 123, 255]],
      [7, [83, 165, 83, 255]],
      [9, [53, 159, 53, 255]],
      [11, [167, 157, 81, 255]],
      [13, [159, 127, 58, 255]],
      [15, [161, 108, 92, 255]],
      [17, [129, 58, 78, 255]],
      [19, [175, 80, 136, 255]],
      [21, [117, 74, 147, 255]],
      [24, [109, 97, 163, 255]],
      [27, [68, 105, 141, 255]],
      [29, [92, 144, 152, 255]],
      [36, [125, 68, 165, 255]],
      [46, [231, 215, 215, 256]],
      [51, [219, 212, 135, 256]],
      [77, [205, 202, 112, 256]],
      [104, [128, 128, 128, 255]],
    ],
  ];

  const [linePath, setLinePath] = useState<string | null>(null);
  //const [minValue, setMinValue] = useState<number | null>(null);
  //const [maxValue, setMaxValue] = useState<number | null>(null);

  const color = interpolateColor(value, colorArray);
  //const linearGradient = createLinearGradient(color, value, minValue, maxValue);

  useEffect(() => {
    if (range && trendline) {
      const { min, max } = range;
      //setMinValue(min);
      //setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      const path = trendline
        .map((val, index) => {
          const x = (index * step).toFixed(2);
          const y = 100 - ((val - 0) / (rangeMultiplier + max - 0)) * 100; // Adjust this calculation if needed
          return `${x} ${y}`;
        })
        .join(" ");
      setLinePath(path);
    }
  }, [value, range, trendline]);

  if (value === null || trendline === null || range === undefined) {

    return null; //Used for non forced panels
  }

  return (
    <div
      className="col-span-1 h-24 flex items-center justify-between rounded-md p-4 transition-all duration-1000 text-white m-1"
      style={{
        background: `${color}`,
        position: "relative",
      }}>
      <div className="flex items-center text-white">
        <div
          style={{
            width: "48px",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none">
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="white"
              strokeWidth="5"
            />

            <polygon
              points="50,25 65,45 35,45"
              fill="white"
              transform={`rotate(${direction}, 50, 50)`}
            />
          </svg>
        </div>
      </div>
      <div className="absolute top-0 h-full right-2 text-3xl font-black text-right z-10 flex items-center">
        {convertToCardinal(direction)}
      </div>
      <div className="text-lg absolute top-0 inset-x-0 p-1 z-10">
        Wind Direction
      </div>
    </div>
  );
};

export default WindDirectionPlate;
